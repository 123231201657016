/* eslint-disable import/no-anonymous-default-export */
import logo from '../assets/newlogo-removebg-preview.png';
import aboutimg from '../assets/aboutimg.jpeg';
import join1 from '../assets/join1.jpeg';
import about from '../assets/image2.jpeg';
import video from "../assets/vid.mp4"

export default {
    logo,
    aboutimg,
    join1,
    about,
    video
}